import React from "react"
import Layout from "../components/layout"
import content from "../../content/what-is-userisk.yaml"
import { Link } from "gatsby"

const About = () => {
  return <Layout>
    <section className="details" id="mainTitle" style={{ background: "white", color: "black" }}>
      <div className="row">
        <div className="twelve columns">

          <br/>
          <h1 className="responsive-headline" style={{ color: "black" }}>{content.headline}</h1>
          <br/>
          {
            content.sections.map((section, index) => <React.Fragment key={index}>
              <div id={`${section.anchorTag}`}  style={{position: 'relative', top: '-60px'}}></div>
              <div>
                <h2 className="responsive-headline" style={{ color: "black" }}>{section.title}</h2>
              </div>
              <div>{section.paragraphs.map(paragraph =>
              {
                if (paragraph.list) {
                  return <ol>
                    {paragraph.list.map(item => <li>{item}</li>)}
                  </ol>
                } else
                  return (<p>{paragraph}</p>)
              })
              }</div>

              <div className="buttons" style={{ textAlign: "left", paddingTop: "0px", marginBottom: "30px" }}
                   id="whatSection">
                <Link className="detailsLink" style={{color: "black"}} id="mc-embedded-subscribe"
                  to={`${section.button.to}`}>{section.button.label}</Link>
              </div>
            </React.Fragment>)
          }
        </div>
      </div>
    </section>
  </Layout>
}

export default About